.main-content-div {
    width: 100%;
}

.main-content-div .menu-btn {
    position: relative;
    top: 40px;
    left: 10px;
}


.Btnclr{
    background:#03009b;
    color: #fff;
}

ion-textarea.form-control {
    height: 100%;
}




/* -------------------------------------For Botton Navigation Bar----------------------------------------------------------- */