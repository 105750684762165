.main-content-div {
    width: 100%;
}

.main-content-div .menu-btn {
    position: relative;
    top: 40px;
    left: 10px;
}


.flex-between {
    display: flex;
    justify-content: space-between;
}

.head-class {
    font-size: 22px;
}
.Btnclr{
    background:#03009b;
    color: #fff;
}

/* -------------------------------------For Botton Navigation Bar----------------------------------------------------------- */