.logo{
    width:150px;
    margin:auto;
    margin-top:20%;
    margin-bottom:50%;
}

.custom-margin-top{
    margin-top:30%;
}

.title{
    font-size: 2em;
    font-weight: 500;
}


.sub-text{
    color: #9CA3AF;
}