.main-content-div {
    width: 100%;
}

.main-content-div .menu-btn {
    position: relative;
    top: 40px;
    left: 10px;
}

.custom-file {
    border: 1px solid #c7c7c7;
    padding: 5px;
    border-radius: 5px;
}

.file-margin {
    margin-bottom: -20px;
}

.Btnclr{
    background:#03009b;
    color: #fff;
}


/* -------------------------------------For Botton Navigation Bar----------------------------------------------------------- */