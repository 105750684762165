.main-content-div {
    width: 100%;
}

.main-content-div .menu-btn {
    position: relative;
    top: 40px;
    left: 10px;
}

.col-md-middle {
    margin-top: 27px;
}

.heading-class{
    font-size:20px;
}

.Btnclr{
    background:#03009b;
    color: #fff;
    margin: 4px 2px;
}

.Btnclr-outline{
    border-color:#03009b;
    color:#03009b;
    margin: 4px 2px;
}

ion-textarea.form-control {
    height: 100%;
}


/* -------------------------------------For Botton Navigation Bar----------------------------------------------------------- */