.main-content-div {
    width: 100%;
}

.main-content-div .menu-btn {
    position: relative;
    top: 40px;
    left: 10px;
}

.border {
    border-bottom: 1px solid #cdcdcd;
}

ion-card-content.menu-item > ion-row > ion-card-subtitle {
    font-size: 1.2em;
}  

.Btnclr{
    background:#03009b;
    color: #fff;
}

ion-card-content.menu-item > ion-row > ion-card-subtitle {
    --color: #03009b;
}

.dashboard-page .profileHeader {
    margin-top: 10rem;
}

/* -------------------------------------For Botton Navigation Bar----------------------------------------------------------- */